//
// Theme
//
// Custom variables followed by theme variables followed by Bootstrap variables
// to ensure cascade of styles.
//
// Bootstrap functions
@import '~bootstrap/scss/functions';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
// Custom variables
@import 'user-variables';
// Theme variables
@import 'theme/variables';
// Bootstrap core
@import '~bootstrap/scss/bootstrap';
// Libs
@import 'libs';
// Theme core
@import 'theme/theme';
// Custom core
@import 'user';
.mt-container {
    position: relative;
}

.position-style {
    font-weight: bold;
    font-size: 2rem;
}

.loadwire {
    position: fixed;
    top: -7px;
    left: calc(50% - 40px);
    z-index: 99999;
}

.cursor-all-scroll {
    cursor: all-scroll;
}

.color-picker {
    opacity: 0;
    width: 23px;
    height: 23px;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

#geocoder-container {
    z-index: 10;
    top: 1rem;
    left: 1rem;
    right: 1rem;
}

#errors-container {
    z-index: 5;
    top: 4rem;
    left: 1rem;
    right: 1rem;
}

#map {
    height: 500px;
}

.mapboxgl-ctrl-geocoder {
    max-width: 100% !important;
    width: 100% !important;
}

.checkbox-check {
    animation: 600ms ease-in-out checkbox_check_anim;
    z-index: 10;
    opacity: 0;
}

.choices__item--disabled {
    background-color: $gray-300 !important;
    color: $gray-700 !important;
}

.choices__item--disabled::after {
    content: "Desactivee";
    font-size: 11px;
    float: right;
}

.qg-question-list {
    max-height: 515px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0;
}

.planing-loader-wrp {
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 10;
}

@keyframes checkbox_check_anim {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(10);
    }
}


/*@media (min-width: 1218px) {
    .monitors-dropdown-wrp{
        position: absolute;
        right: 0;
        top: 0;
        width: 14rem;
    }

    .fc-header-toolbar{
        padding-right: 15rem;
    }
}*/

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
}

.navbar-vertical.navbar-expand-md::-webkit-scrollbar {
    width: 10px;
}

.navbar-vertical.navbar-expand-md {
    scrollbar-width: thin;
    scrollbar-color: #E3EBF6 white;
}

.navbar-vertical.navbar-expand-md::-webkit-scrollbar-track {
    background: white;
}

.navbar-vertical.navbar-expand-md::-webkit-scrollbar-thumb {
    background-color: #E3EBF6;
}

.form-check-input,
.form-check-input:focus:not(:checked) {
    background-color: #9eb2cd;
}

.disable-events * {
    pointer-events: none;
}

.js-cookie-consent {
    position: fixed !important;
    bottom: 1rem;
    right: 1rem;
    width: 300px;
    padding: 20px !important;
    color: #000;
    text-align: center;
    background-color: #ffffff !important;
    font-size: 14px !important;
    z-index: 100;
}

.js-cookie-out {
    z-index: 9999;
    position: relative;
}

@media screen and (max-width: 600px) {
    .js-cookie-consent {
        position: fixed !important;
        bottom: 0;
        right: 0;
        width: 100%;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .js-cookie-in {
        background-position: 0 130% !important;
        background-size: 60px !important;
    }
}

.mh-scroll{
    max-height: 700px;
    overflow-y: auto;
}
#elevesList {
    .card-header .col {
        display: flex;
        align-items: center;
        .filter {
            position: relative;
            button {
                border: 0;
                background-color: transparent;
                box-shadow: 0 0 1px 1px #eee;
                border-radius: 3px;
                padding: 4px 5px;
                cursor: pointer;
                height: max-content;
            }
            .filter-block {
                background-color: white;
                box-shadow: 0 0 2px 2px #eee;
                width: 300px;
                position: absolute;
                z-index: 1;
                right: 0;
                top: 120%;
                display: none;
                .title {
                    padding: 10px;
                    font-size: 1.3em;
                    font-weight: 100;
                }
                ul.areas {
                    padding: 0;
                    max-height: 200px;
                    overflow-y: auto;
                    li {
                        list-style: none;
                        cursor: pointer;
                        padding: 10px 20px;
                        &:hover {
                            background-color: #eeeeee94;
                        }
                    }
                }
                button {
                    margin: 15px auto;
                    display: block;
                    padding: 5px 25px;
                    background-color: #2c7be5;
                    color: white;
                }
            }
        }
    }
}
//
// user.scss
// Use this to write your custom SCSS
//

.model-list{
    height: 300px;
    overflow-y: auto;

    & div .item .card{
        margin-bottom: 0.75rem;
    }

    & div:last-child .item .card{
        margin-bottom: 0;
    }
}

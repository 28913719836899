//
// utilities.scss
// Extended from Bootstrap
//

@import 'utilities/background';
@import 'utilities/lift';

.bg-white{
    background-color: #fff !important;
}